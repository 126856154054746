<template>
  <meta property="og:image" content="https://i.imgur.com/SrFna5j.png" />
  <meta property="twitter:card" content="summary_large_image">
  <main
    class="mt-10 md:mt-1 flex flex-col-reverse gap-8 items-center md:flex-row md:gap-16 md:justify-center min-h-[65vh] md:min-h-[80vh]">
    <div>
      <div class="space-y-3 text-center md:text-left px-10">
        <p class=" text-amber-200 hello">Hello :DDD, I am</p>
        <h1 class="text-4xl font-bold md:text-5xl text-white fadein-up">ngao._</h1>
        <div class="py-2">
          <h1
            class="typewrite text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-slate-100 to-yellow-500 md:text-2xl fadein-up"
            ref="typewriter">
            <span class="wrap">{{ txt }}</span>
          </h1>
        </div>
        <p class="text-white pr-4 fade-in-from-left">Queo cum to my Personal website. <span class="wave">👋🏼</span></p>
        <br>
      </div>
    </div>
    <!-- <button
        class="fadein-bot fade-500 flex items-center py-2 px-4 mx-auto text-sm font-medium rounded-lg border transition duration-300 md:py-2.5 md:px-5 md:mx-0 text-amber-200 border-amber-200 hover:bg-amber-200 hover:bg-opacity-10 bg-transparent focus:outline-none w-fit"><svg
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="mr-2 w-4 h-4">
          <path fill-rule="evenodd"
            d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm5.845 17.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V12a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z"
            clip-rule="evenodd"></path>
          <path
            d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z">
          </path>
        </svg>Download Resume</button> -->

    <div class="flex justify-center md:justify-start fadein-right"><img alt="avatar" fetchpriority="high" width="200"
        height="200" decoding="async" data-nimg="1"
        class="w-10/12 md:h-auto rounded-full border-4 border-amber-200 pict"
        src="https://i.imgur.com/s1R5HTu.jpeg">
    </div>
  </main>
</template>

<script>
export default {
  name: 'HomeViewv2',
  props: {
    bannerImage: {
      type: String,
      default: "https://cdn.discordapp.com/banners/781534754545598494/a_627a9df121a05666d88660dd1d1cb431.gif?size=600"
    },
    bannerColor: {
      type: String,
      default: "#5865F2"
    },
    avatar: {
      type: String,
      default: "https://cdn.discordapp.com/avatars/781534754545598494/763a1916a940f593f8d1389dc0346adb.webp?size=160",
      required: true
    },
    badges: {
      type: Array,
      default: null,
      required: false
    },
    username: {
      type: String,
      default: "Ngáo",
      required: true
    },
    discriminator: {
      type: String,
      default: "</>",
      required: false
    },
    userLabel: {
      type: String,
      default: null,
      required: false
    },
    memberSince: {
      type: String,
      default: "Nov 26, 2020",
      required: false
    },
    roles: {
      type: Array,
      default: null,
      required: false
    },
    availableRoles: {
      type: Array,
      default: null,
      required: false
    },
    acceptMessages: {
      type: Boolean,
      default: false,
      required: false
    },
    button: {
      type: Object,
      default: null,
      required: false
    },
    userId: {
      type: String,
      default: "781534754545598494",
      required: false
    },
    nick: {
      type: String,
      default: "ngao._",
      required: false
    }
  },
  data() {
    return {
      toRotate: ["Student", "Learning", "IT", "Coder"],
      period: 1000,
      txt: '',
      loopNum: 0,
      isDeleting: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.tick();
    });
  },
  methods: {
    tick() {
      let typewriter = this.$refs.typewriter;

      if (!typewriter) {
        return;
      }

      let i = this.loopNum % this.toRotate.length;
      let fullTxt = this.toRotate[i];

      this.txt = this.isDeleting ? fullTxt.substring(0, this.txt.length - 1) : fullTxt.substring(0, this.txt.length + 1);
      typewriter.innerHTML = `<span class="wrap">${this.txt}</span>`;

      let that = this;
      let delta = 200 - Math.random() * 100;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      setTimeout(() => {
        that.tick();
      }, delta);
    },
    randomWord() {
      return this.availableRoles[Math.floor(Math.random() * this.availableRoles.length)];
    },
    addRole(card) {
      const role = document.createElement("div");
      const roleColor = document.createElement("div");
      const roleText = document.createElement("p");
      role.classList.add("role");
      roleColor.classList.add("role-color");
      roleColor.style.backgroundColor = this.randomHexColor();
      roleText.innerText = this.randomWord();
      role.append(roleColor, roleText);
      card.parentElement.append(role, card);
      roleColor.onclick = (e) => e.target.parentElement.remove();
    },
    randomHexColor() {
      return "#" + Math.floor(Math.random() * 16777215).toString(16);
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-family: "JetBrains Mono", system-ui;
  box-sizing: border-box;
}

.hello {
  margin-right: 150px;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

.typewrite>.wrap {
  border-right: 0.08em solid #fff;
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg)
  }

  10% {
    transform: rotate(14deg)
  }

  20% {
    transform: rotate(-8deg)
  }

  30% {
    transform: rotate(14deg)
  }

  40% {
    transform: rotate(-4deg)
  }

  50% {
    transform: rotate(10deg)
  }

  60% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(0deg)
  }
}

.pict {
  box-shadow: 0px 0px 73px -9px rgba(255, 219, 112, 0.44);
  -webkit-box-shadow: 0px 0px 73px -9px rgba(255, 219, 112, 0.44);
  -moz-box-shadow: 0px 0px 73px -9px rgba(255, 219, 112, 0.44);
}

.fadein-up {
  opacity: 0;
  animation-name: fadeInUp;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fade-in-from-left {
  opacity: 0;
  animation: fadeInLeft 0.5s ease-out forwards;
  animation-delay: 500ms;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadein-right {
  opacity: 0;
  animation: fadeInRight 0.5s ease-out forwards;
  animation-delay: 500ms;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadein-bot {
  opacity: 0;
  animation: fadeInBot 0.5s forwards;
}

@keyframes fadeInBot {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadein-1 {
  animation-delay: 200ms;
}

.fadein-2 {
  animation-delay: 400ms;
}

.fadein-3 {
  animation-delay: 600ms;
}

.fade-500 {
  animation-delay: 500ms;
}

.space {
  height: 20%;
  padding-top: 20px;
}

.dot {
  color: white;
  font-weight: 1000;
  font-size: large;
}

.tooltip {
  display: block;
  position: absolute;
  color: #b6b7b7;
  background: #18191c;
  padding: 0.4rem;
  border-radius: 3px;
  max-width: 150px;
  width: max-content;
  font-size: 0.9rem;
  transform: scale(0);
  transition: 0.055s ease-in-out transform;
  z-index: 10;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
}

.tooltip-up {
  bottom: 42px;
}

.tooltip-up::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #18191c;
}

.nick {
  color: #b9bbbe;
  font-weight: 600;
  font-size: 0.95rem;
  margin-top: -20px;
  margin-left: -165px;

}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #6d5cff;
  padding: 10px;
  border-radius: 3px;
  color: #fff;
  font-size: 0.85rem;
  transition: 0.2s ease-in-out;
  margin-top: 12px;
}

.btn:hover {
  background-color: #4752c4;
}

.card {
  background: #000000;
  width: 345px;
  border-radius: 12px;
  max-height: 100%;
  height: max-content;
  box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.2);
  scrollbar-width: none;
  margin: auto;
}

.card::-webkit-scrollbar {
  display: none;
}

.card-header .banner {
  width: 100%;
  height: 60px;
  background: v-bind(bannerColor);
  border-radius: 6px 6px 0 0;
  overflow: hidden;
}

.card-header .banner-img {
  width: 100%;
  height: 100px;
  background-position: center !important;
  background-size: 100% auto !important;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
}

.card-body {
  padding: 15px;
  position: relative;
}

.card-body .profile-header {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: calc(100% - 30px);
  top: -50px;
}

.card-body .profile-header .profil-logo {
  position: relative;
  margin-left: 10px;
  border: solid 5px #222325;
  border-radius: 50%;
  top: -5px;
}

.card-body .profile-header .profil-logo img {
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.card-body .profile-header .profil-logo::before {
  content: "See Profile";
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  color: #eeeeee;
  background: #0000007e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  transition-duration: 0.15s;
}

.card-body .profile-header .profil-logo:hover::before {
  opacity: 1;
}

.card-body .profile-header .badges-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  max-width: 150px;
  background: #18191c;
  border-radius: 7px;
  padding: 3px;
}

.card-body .profile-header .badges-container .badge-item {
  position: relative;
  margin: 5px;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.card-body .profile-header .badges-container .badge-item img {
  height: 100%;
}

.card-body .profile-header .badges-container .badge-item:hover>.tooltip {
  transform: scale(1);
}

.card-body .profile-body {
  background: #16181d;
  border-radius: 7px;
  padding: 20px;
  margin-top: 30px;
  margin-top: 30%;
}

.username {
  margin-top: -78px;
  margin-left: -13px;
}

.card-body .profile-body .username {
  color: #eeeeee;
  margin-bottom: 13px;
  font-weight: 600;
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-body .profile-body .username span {
  color: #b9bbbe;
}

.card-body .profile-body .username .badge {
  font-size: 0.65rem;
  background-color: #5865f2;
  text-transform: uppercase;
  font-weight: 300;
  width: max-content;
  padding: 2px 5px;
  margin-left: 5px;
  border-radius: 3px;
}

.card-body .profile-body hr {
  border: none;
  border-top: 0.5px solid #33353b;
}

.category-title slot {
  font-weight: 600;
}

.card-body .profile-body .category-title {
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-bottom: 8px;
}

.card-body .profile-body .basic-infos {
  margin-bottom: 14px;
  margin-top: 12px;
}

.card-body .profile-body .basic-infos p {
  color: #bdbebf;
  font-size: 0.9rem;
}

::v-slotted(.card-body .profile-body .basic-infos p a) {
  color: #02a5e6;
  text-decoration: none;
}

::v-slotted(.card-body .profile-body .basic-infos p a):hover {
  text-decoration: underline;
}

::v-slotted(.card-body .profile-body .basic-infos p b) {
  color: #ddd;
}

.card-body .profile-body .roles {
  margin-bottom: 14px;
}

.card-body .profile-body .roles .roles-list {
  display: flex;
  flex-wrap: wrap;
}

.card-body .profile-body .roles .roles-list .role {
  background: #292b2f;
  color: #f5f5f5;
  border-radius: 3px;
  font-size: 0.85rem;
  font-weight: 300;
  padding: 3px 6px;
  margin-right: 4px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.card-body .profile-body .roles .roles-list .role .role-color {
  position: relative;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin-right: 5px;
}

.card-body .profile-body .roles .roles-list .role .role-color:hover::before {
  content: "✖";
  position: absolute;
  top: -2px;
  right: -2px;
  font-size: 0.65rem;
  color: #f5f5f5;
  background: #292b2f;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.card-body .profile-body .roles .roles-list .role-add {
  cursor: pointer;
}

.card-body .profile-body .note textarea {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  min-height: 30px;
  color: #e0e0e0;
  resize: none;
  font-size: 0.8rem;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  scrollbar-width: none;
}


.card-body .profile-body .note textarea::-webkit-scrollbar {
  display: none;
}

.card-body .profile-body .note textarea::placeholder {
  font-size: 0.8rem;
}

.card-body .profile-body .note textarea:focus::placeholder {
  opacity: 0;
}

.card-body .profile-body .message input {
  background: transparent;
  outline: none;
  border: 1.2px solid #4b4b4b;
  padding: 13px;
  width: 90%;
  border-radius: 4px;
  color: #eeeeee;
  margin-top: 14px;
}
</style>
