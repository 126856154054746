import { createRouter, createWebHistory } from 'vue-router'
import HomeViewv2 from '../views/Homev2.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeViewv2,
    meta: { title: 'Home' }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: { title: 'About' }
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import('../views/PortfolioView.vue'),
    meta: { title: 'Portfolio' }
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/BlogView.vue'),
    meta: { title: 'Blog' }
  },
  {
    path: '/read/:slug/:id',
    name: 'articleDetail',
    component: () => import('../views/ArticleView.vue'),
    meta: { title: 'Blog' }
  },
  {
    path: '/homev2',
    name: 'Homev2',
    component:() => import('../views/HomeView.vue'),
    meta: { title: 'HomeV2' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' - ngao._'; // Mengambil judul dari meta atau mengatur judul default jika tidak ada
  next();
});

export default router
